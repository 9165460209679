import React from 'react'
import {
  useTable,
  Table,
  TextField,
  Space,
  ShowButton,
  CreateButton,
  Modal,
  Button,
  Tag,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  useDelete,
  useInvalidate,
  useCan,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { TCommonError } from 'src/interfaces/common'
import { TFilterForm } from '@components/ContentFilter'
import { ParticipantDetails } from './blocks/ParticipantDetails'
import { CreateParticipantSection } from './blocks/CreateParticipantSection'
import { SideFilterParticipantForm } from './blocks/SideFilterParticipantForm'
import { WithParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import useGetACL from 'src/hooks/useGetACL'
import { ParticipantEntryQueue } from './blocks/ParticipantEntryQueue'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'
import { kapabilitasTags } from './blocks/utils'
import { useProgramStore } from '@resources/lms-management/program/store'

import type { TLMSParticipant } from './types'

type TTableParticipantListProps = {
  pageType?: string
  programStatus?: string
}

export const TableParticipantList: React.FC<
  IResourceComponentsProps<GetListResponse<any>> & TTableParticipantListProps
> = ({ pageType = '', programStatus = null }) => {
  const [rerenderKey, setRerenderKey] = React.useState(0)
  const router = useRouter()

  const [, , lmsRole] = useGetACL()
  const {
    participantEmail = null,
    mode = 'view',
    programId = '',
    programLocalId = '',
    id = '',
  } = router.query

  const { mutateAsync: unassignParticipant } = useDelete()

  const actualProgramId = !!programId ? programId : id
  const programLocalResourceURL =
    programId && !programLocalId
      ? `local-programs/${id}/participants`
      : 'participants'

  const actualResourceURL =
    programId && programLocalId
      ? `local-programs/${programLocalId}/classes/${id}/participants`
      : `${programLocalResourceURL}`

  const invalidate = useInvalidate()
  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  const [isShown, setIsShown] = React.useState(false)
  const { tableProps, searchFormProps } = useTable<
    TLMSParticipant,
    TCommonError,
    TFilterForm<any>
  >({
    resource: `programs/${actualProgramId}/${actualResourceURL}`,
    dataProviderName: 'lms',
    permanentFilter:
      pageType === 'PROGRAM_LOKAL_LIST'
        ? [
            {
              field: 'assigned',
              operator: 'eq',
              value: true,
            },
          ]
        : [],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const formParams = params
      const { searchMode = 'name' } = params

      const constructFilters = (key: string, value: any) => {
        if (key === 'searchMode') return

        if (key === 'searchQuery') {
          filters.push({
            field: searchMode === 'name' ? 'email' : 'name',
            operator: 'eq',
            value: '',
          })

          filters.push({
            field: searchMode,
            operator: 'eq',
            value,
          })

          return
        }

        filters.push({
          field: key,
          operator: 'eq',
          value,
        })
      }

      Object.entries(formParams).forEach(([key, value]) => {
        constructFilters(key, value)
      })

      return filters
    },
  })

  const handleParticipantDetailShowClick = (
    email: string,
    mode: string = 'view',
  ) => {
    router.replace({
      pathname: '/[resource]/[action]/[id]',
      query: {
        ...router.query,
        participantEmail: email,
        mode,
      },
    })
  }

  const handleUnassignParticipant = (userId: string) => {
    unassignParticipant({
      resource: `programs/${actualProgramId}/${actualResourceURL}`,
      dataProviderName: 'lms',
      metaData: {
        payload: {
          userId,
        },
      },
      id: '',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    })
  }

  const allowModify = lmsRole !== 'admin-entitas' || isOwner
  const { data: canUpdateParticipantCertificateFlag } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'certificate-participant-flag' },
  })

  return (
    <WithParticipantFilterContext>
      <SideFilterParticipantForm
        programId={actualProgramId as string}
        searchRowChildren={
          !programId && (
            <div>
              {pageType === 'PROGRAM_LIST' && (
                <ParticipantEntryQueue
                  programId={actualProgramId as string}
                  key={`participant-entry-queue-${rerenderKey}`}
                />
              )}
              {programStatus === PROGRAM_STATE.PREPARATION && (
                <CreateButton
                  size="large"
                  onClick={() => {
                    setIsShown(true)
                  }}
                >
                  Tambah
                </CreateButton>
              )}
            </div>
          )
        }
        formInstance={searchFormProps}
      >
        <div className={`${participantEmail && 'flex flex-row'} `}>
          <Modal
            title="Tambah Partisipan"
            visible={isShown}
            onCancel={() => setIsShown(false)}
            className="upload-or-select-modal"
            destroyOnClose
            footer={null}
          >
            <CreateParticipantSection
              onCancel={() => setIsShown(false)}
              onSuccess={() => {
                invalidate({
                  resource: `programs/${actualProgramId}/${actualResourceURL}`,
                  dataProviderName: 'lms',
                  invalidates: ['list'],
                })

                setRerenderKey(rerenderKey + 1)
              }}
            />
          </Modal>

          <div className={`${participantEmail && 'w-[53vw] overflow-x-auto'}`}>
            <Table {...tableProps} rowKey="email">
              <Table.Column
                dataIndex="userId"
                title="User ID"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                width={200}
                dataIndex="email"
                title="E-mail"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                dataIndex="name"
                title="Nama"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                dataIndex="participantType"
                title="Tipe"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                dataIndex="jenjang"
                title="Jenjang"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                dataIndex="provinsi"
                title="Provinsi"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                dataIndex="kapabilitas"
                title="Kapabilitas"
                render={(value) => kapabilitasTags(value)}
              />
              {canUpdateParticipantCertificateFlag?.can && (
                <Table.Column
                  dataIndex="flagSertifikat"
                  title="Flag Sertifikat"
                  render={(value) =>
                    value ? (
                      <Tag className="uppercase" color="processing">
                        true
                      </Tag>
                    ) : (
                      <Tag className="uppercase" color="error">
                        false
                      </Tag>
                    )
                  }
                />
              )}
              <Table.Column
                render={(_, record: any) => {
                  return (
                    <Space direction="vertical">
                      <ShowButton
                        block
                        size="small"
                        onClick={() => {
                          handleParticipantDetailShowClick(record.email)
                        }}
                        disabled={
                          record.email === participantEmail && mode === 'view'
                        }
                      >
                        Lihat
                      </ShowButton>

                      {allowModify && (
                        <>
                          <Button
                            block
                            size="small"
                            onClick={() => {
                              handleParticipantDetailShowClick(
                                record.email,
                                'edit',
                              )
                            }}
                            icon={<EditOutlined />}
                          >
                            Ubah
                          </Button>

                          {programStatus === PROGRAM_STATE.PREPARATION &&
                            pageType === 'PROGRAM_LOKAL_LIST' && (
                              <Button
                                block
                                size="small"
                                onClick={() => {
                                  handleUnassignParticipant(record.userId)
                                }}
                                danger
                                icon={<DeleteOutlined />}
                              >
                                Unassign
                              </Button>
                            )}
                        </>
                      )}
                    </Space>
                  )
                }}
              />
            </Table>
          </div>
          {participantEmail && (
            <div className="ml-4 w-[500px]">
              <ParticipantDetails
                key={`${participantEmail as string}-${mode as string}`}
              />
            </div>
          )}
        </div>
      </SideFilterParticipantForm>
    </WithParticipantFilterContext>
  )
}
