import {
  Button,
  Icons,
  message,
  notification,
  RcFile,
  Upload,
  UploadProps,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

import { getGuruToken } from 'src/helpers/session'

type UploadParticipantDataButton = {
  programId?: string | string[] | number
  onSuccess?: () => void
}
const UploadParticipantDataButton = (props: UploadParticipantDataButton) => {
  const [loading, setLoading] = useState(false)
  const uploadProps: UploadProps = {
    name: 'participant',
    action:
      process.env.NEXT_PUBLIC_LMS_API_URL +
      `/programs/${props.programId}/participant-queue/uploads`,
    headers: {
      Authorization: `Bearer ${getGuruToken()}`,
    },
    onChange(info) {
      if (info.file.status === 'uploading') {
        setLoading(true)
      } else {
        setLoading(false)
      }
      if (info.file.status === 'done') {
        notification.success({
          description: 'Data partisipan berhasil diunggah dan sedang diproses',
          message: 'Sukses',
        })
        props.onSuccess?.()
      } else if (info.file.status === 'error') {
        notification.error({
          description: 'Terdapat gangguan saat mengunggah CSV',
          message: 'Error',
        })
      }
    },
    beforeUpload: async (file: RcFile) => {
      const isCsv = file.type === 'text/csv'
      if (!isCsv) {
        message.error('Hanya dapat mengunggah berkas CSV')
        return false
      }
    },
  }

  return (
    <Upload
      {...uploadProps}
      accept=".csv"
      multiple={false}
      showUploadList={false}
      key="participantDataUpload"
    >
      <Button icon={<Icons.UploadOutlined />} type="primary" loading={loading}>
        Unggah Data
      </Button>
    </Upload>
  )
}

export default UploadParticipantDataButton
