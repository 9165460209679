import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Empty,
  Input,
  Modal,
  ModalProps,
} from '@pankod/refine-antd'
import { Paintbrush2, Fullscreen } from 'lucide-react'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

import { CANVAS_PREVIEW_DOM_ID } from '../constants'
import { CanvasFooter } from './CanvasFooter'
import {
  CertificateTemplateVariablesWithDefault,
  CertificateTemplateVariables,
} from '../../types'
import {
  getSVGStringFromUrl,
  translateSVGCustomVarToTemplate,
} from '../utils/canvas'

export type ModalPreviewSVGProps = {
  modalProps: ModalProps
  svgContent: {
    front: string
    back: string
  }
}

export type ButtonSVGPreviewProps = {
  onClick: () => Promise<{ front: string; back: string } | undefined>
  initialTemplateUrl?: {
    front: string
    back: string
  }
  variables:
    | CertificateTemplateVariables
    | CertificateTemplateVariablesWithDefault
    | undefined
  shouldLoadInitialTemplate: {
    front: boolean
    back: boolean
  }
  className?: string
  buttonProps?: ButtonProps
}

export const ModalPreviewSVG = (props: ModalPreviewSVGProps) => {
  const INITIAL_SCALE = 0.5
  const INITIAL_TRANSLATE_X = -460
  const INITIAL_TRANSLATE_Y = -770
  const { modalProps, svgContent } = props
  const [variableList, setVariableList] = useState<Map<string, string>>(
    new Map(),
  )
  const [frontSVGString, setFrontSVGString] = useState<string>(svgContent.front)
  const [backSVGString, setBackSVGString] = useState<string>(svgContent.back)
  const [showTooltip, setShowTooltip] = useState(false)
  useEffect(() => {
    if (modalProps.visible) {
      setFrontSVGString(svgContent.front)
      setBackSVGString(svgContent.back)
      // transform #canvas-preview-wrapper position to fit with zoom out
      const canvasPreviewWrapper = document.getElementById(
        'canvas-preview-wrapper',
      )

      // translate canvasPreviewWrapper to top left
      if (canvasPreviewWrapper) {
        canvasPreviewWrapper.style.transform = `scale(${INITIAL_SCALE}) translate(${INITIAL_TRANSLATE_X}px, ${INITIAL_TRANSLATE_Y}px)`
      }

      generateControlForm(svgContent.front, svgContent.back)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.visible])

  const generateControlForm = (front: string, back: string) => {
    const frontVarList = front.match(/{{(.*?)}}/g) ?? []
    const backVarList = back.match(/{{(.*?)}}/g) ?? []

    const variables = new Map(
      [...frontVarList, ...backVarList].map((v) => [v, v]),
    )
    setVariableList(variables)
  }

  const handleApplyPreviewChanges = (
    variables: Map<string, string>,
    svgContent: { front: string; back: string },
  ) => {
    const result = (() => {
      let frontSVGStringVar = svgContent.front
      let backSVGStringVar = svgContent.back

      variables.forEach((value, key) => {
        frontSVGStringVar = frontSVGStringVar.replaceAll(key, value)
        backSVGStringVar = backSVGStringVar.replaceAll(key, value)
      })

      return {
        frontSVGStringVar,
        backSVGStringVar,
      }
    })()

    setFrontSVGString(result.frontSVGStringVar)
    setBackSVGString(result.backSVGStringVar)
  }

  return (
    <Modal
      {...modalProps}
      onCancel={(e) => {
        setShowTooltip(false)
        modalProps.onCancel?.(e)
      }}
      title={'Pratinjau Template Sertifikat'}
      destroyOnClose
      width={900}
      centered
    >
      <div className="flex justify-center  bg-slate-300">
        <div
          className={clsx(
            'w-[80%] max-h-[70vh] overflow-hidden',
            showTooltip && 'with-attribute-tooltip',
          )}
        >
          <CanvasFooter
            targetWrapperId="canvas-preview-wrapper"
            initialScale={INITIAL_SCALE}
            initialPan={{
              x: INITIAL_TRANSLATE_X,
              y: INITIAL_TRANSLATE_Y,
            }}
            className="!bottom-11"
            defaultPanEnabled
          />
          <div
            className="w-fit flex gap-12 flex-col peer-[.canvas-grab]:*:*:!cursor-grab peer-[.canvas-grabbing]:*:*:!cursor-grabbing"
            id="canvas-preview-wrapper"
          >
            <div className="w-[297mm] relative">
              <div
                style={{
                  width: '297mm',
                  height: '210mm',
                  background: 'white',
                  zIndex: 1,
                  userSelect: 'none',
                }}
                id="front-page-preview"
                className='before:content-["Halaman_Depan"] before:-top-8 before:font-bold before:text-xl before:left-1/2 before:-translate-x-1/2 before:absolute'
              >
                <div dangerouslySetInnerHTML={{ __html: frontSVGString }} />
              </div>
            </div>
            <div className="w-[297mm] relative">
              <div
                style={{
                  width: '297mm',
                  height: '210mm',
                  background: 'white',
                  zIndex: 1,
                  userSelect: 'none',
                }}
                id="back-page-preview"
                className='before:content-["Halaman_Belakang"] before:-top-8 before:font-bold before:text-xl before:left-1/2 before:-translate-x-1/2 before:absolute'
              >
                <div dangerouslySetInnerHTML={{ __html: backSVGString }} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[200px] border border-gray-30 certificate-template__preview-sidebar rounded grid grid-rows-[20px_16px_1fr_96px] gap-2">
          <h4 className="text-center p-2 pb-0 !m-0">Daftar Variable Aktor</h4>
          <Divider className="!my-2" />
          <>
            <div className="px-2 flex flex-col gap-2 h-[520px] overflow-auto">
              {variableList.size === 0 && (
                <div className="p-2">
                  <Empty
                    description={'Tidak ada variable aktor terpasang'}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
              {Array.from(variableList).map(([key], index) => (
                <div
                  key={`front-var-${index}`}
                  className="border h-fit border-slate-200 rounded shadow-sm p-2 flex flex-col gap-1"
                >
                  <label htmlFor={key} className="flex flex-row items-center">
                    <h5
                      title={key.replace(/{{\s*(.*?)\s*}}/, '$1')}
                      className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis !mb-0 font-mono"
                    >
                      {key.replace(/{{\s*(.*?)\s*}}/, '$1')}
                    </h5>
                  </label>
                  <Input
                    type="text"
                    id={key}
                    name={key}
                    allowClear
                    size="small"
                    className="w-full border rounded"
                    onBlur={(e) => {
                      const value = e.target.value
                      setVariableList((map) => {
                        map.set(key, value || key)
                        return map
                      })
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex gap-1 flex-col p-2">
              <Button
                block
                onClick={() =>
                  handleApplyPreviewChanges(variableList, svgContent)
                }
                type="primary"
                className="!flex gap-1 items-center justify-center"
                icon={<Paintbrush2 className="h-5 w-5" />}
                disabled={variableList.size === 0}
              >
                Terapkan
              </Button>
              <div>
                <Checkbox
                  onChange={(e) => {
                    setShowTooltip(e.target.checked)
                  }}
                  checked={showTooltip}
                >
                  Tampilkan informasi komponen variable
                </Checkbox>
              </div>
            </div>
          </>
        </div>
      </div>
    </Modal>
  )
}

export const ButtonSVGPreview = (props: ButtonSVGPreviewProps) => {
  const { onClick, initialTemplateUrl, variables, shouldLoadInitialTemplate } =
    props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [frontSVGString, setFrontSVGString] = useState<string>('')
  const [backSVGString, setBackSVGString] = useState<string>('')
  const [isPreviewModalVisible, setPreviewModalVisible] =
    useState<boolean>(false)

  const handleLoadSVGString = async () => {
    setIsLoading(true)
    const { front: doLoadInitialFront, back: doLoadInitialBack } =
      shouldLoadInitialTemplate
    const { front, back } = initialTemplateUrl ?? { front: '', back: '' }

    const svgOutput = await onClick()

    const getCurrentFrontSVGString = async () => {
      if (doLoadInitialFront) {
        return await getSVGStringFromUrl(front, variables, true)
      }
      if (!svgOutput) return ''
      return translateSVGCustomVarToTemplate(svgOutput.front, variables, true)
    }

    const getCurrentBackSVGString = async () => {
      if (doLoadInitialBack) {
        return await getSVGStringFromUrl(back, variables, true)
      }
      if (!svgOutput) return ''
      return translateSVGCustomVarToTemplate(svgOutput.back, variables, true)
    }

    const [currentFrontSVGString, currentBackSVGString] = await Promise.all([
      getCurrentFrontSVGString(),
      getCurrentBackSVGString(),
    ])

    setFrontSVGString(currentFrontSVGString || '')
    setBackSVGString(currentBackSVGString || '')

    setPreviewModalVisible(true)
    setIsLoading(false)
  }

  return (
    <div>
      <div className="hidden">
        <canvas id={CANVAS_PREVIEW_DOM_ID['canvas-front-page']} />
        <canvas id={CANVAS_PREVIEW_DOM_ID['canvas-back-page']} />
      </div>
      <ModalPreviewSVG
        modalProps={{
          visible: isPreviewModalVisible,
          onCancel: () => {
            setPreviewModalVisible(false)
          },
          width: '80%',
          footer: () => null,
        }}
        svgContent={{
          front: frontSVGString,
          back: backSVGString,
        }}
      />
      <div
        className={clsx(
          'absolute top-7 right-6 m-2 z-10 rounded p-2 shadow certificate-template__button-preview-wrapper w-[250px]',
          props.className,
        )}
      >
        <Button
          icon={<Fullscreen className="h-5 w-5" />}
          onClick={() => handleLoadSVGString()}
          loading={isLoading}
          className="!flex gap-2 items-center justify-center"
          block
          {...props.buttonProps}
        >
          Lihat Pratinjau
        </Button>
      </div>
    </div>
  )
}
