import React, { useRef, useState } from 'react'
import {
  Button,
  Collapse,
  Icons,
  Input,
  Space,
  Table,
} from '@pankod/refine-antd'
import Highlighter from 'react-highlight-words'
import dayjs from 'dayjs'

import { ParticipantUpload } from '../type'
import { TCommonError } from 'src/interfaces/common'
import useTableWithMeta from 'src/hooks/useTableWithMeta'

import type { ColumnType } from 'antd/es/table'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { InputRef } from 'antd'

type UploadParticipantTableList = {
  programId?: string | string[] | number
  localProgramId?: string | string[] | number
  type: ParticipantUpload['type']
  variant: 'queue' | 'failed'
}

const UploadParticipantTableList = ({
  programId,
  type,
  variant,
  localProgramId,
}: UploadParticipantTableList) => {
  const { tableProps } = useTableWithMeta<ParticipantUpload, TCommonError>({
    resource:
      variant === 'queue'
        ? `programs/${programId}/participant-queue`
        : `programs/${programId}/participant-queue/failed`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!localProgramId,
    },
    permanentFilter: [
      {
        field: 'type',
        operator: 'eq',
        value: type,
      },
      ...(localProgramId
        ? [
            {
              field: 'programLocalId',
              operator: 'eq' as const,
              value: localProgramId,
            },
          ]
        : []),
    ],
    syncWithLocation: true,
  })
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (): ColumnType<ParticipantUpload> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: true })
              setSearchText((selectedKeys as string[])[0])
            }}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{ width: 150 }}
          >
            Cari Identifier
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Icons.SearchOutlined
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  })

  return (
    <Table
      {...tableProps}
      rowKey={'id'}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <div className="flex flex-col gap-1">
              <Collapse>
                <Collapse.Panel header="Payload" key="payload">
                  <pre>
                    {JSON.stringify(JSON.parse(record.payload), null, 2)}
                  </pre>
                </Collapse.Panel>
                <Collapse.Panel header="Last Error" key="last-err">
                  <pre className="w-full overflow-auto">{record.lastErr}</pre>
                </Collapse.Panel>
              </Collapse>
            </div>
          )
        },
      }}
      tableLayout="fixed"
      columns={[
        { title: 'ID', dataIndex: 'id' },
        {
          title: 'Identifier',
          dataIndex: 'identifier',
          ...getColumnSearchProps(),
        },
        { title: 'Jenis Identifier', dataIndex: 'identifierType' },
        { title: 'Author ID', dataIndex: 'authorId' },
        {
          title: 'Updated At',
          dataIndex: 'lastUpdatedAt',
          render: (value) => dayjs(value).format(),
        },
      ]}
    />
  )
}

export default UploadParticipantTableList
