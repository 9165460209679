import { Icons, Divider, List, Space, Tabs } from '@pankod/refine-antd'
import {
  GetListResponse,
  IResourceComponentsProps,
  useNavigation,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import Head from 'next/head'

import { NestedBreadcrumb } from '@resources/lms-management/components/NestedBreadcrumb'
import DownloadRegionCodeButton from './components/DownloadRegionCodeButton'
import { Participant } from './type'
import DownloadTemplateData from './components/DownloadTemplateData'
import UploadParticipantDataButton from './components/UploadParticipantDataButton'
import ParticipantTableList from './blocks/ParticipantTableList'
import { trackTabChange } from 'src/helpers/url'
import UploadParticipantTableList from './blocks/UploadParticipantTableList'

const ParticipantManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<Participant>>
> = ({ options }) => {
  const router = useRouter()
  const { activeTab = null } = router.query || {}
  const { listUrl, goBack, showUrl } = useNavigation()
  const programId = String(router.query?.programId)

  const [activeKey, setActiveKey] = useState(
    (activeTab as string) || 'daftar-peserta',
  )

  return (
    <List
      headerProps={{
        backIcon: <Icons.ArrowLeftOutlined />,
      }}
      breadcrumb={
        <NestedBreadcrumb
          hideOriginal
          injectedItems={[
            {
              label: 'LMS Management',
              icon: <Icons.SolutionOutlined />,
            },
            {
              label: 'Program',
              href: listUrl('lms-program-management'),
            },
            {
              label: programId,
              href: showUrl('lms-program-management', programId),
            },
            {
              label: `Manajemen Data Peserta Tersier`,
            },
          ]}
        />
      }
      title={
        <div className="flex gap-4 items-center">
          <Icons.ArrowLeftOutlined
            className="ant-page-header-back-button"
            style={{ fontSize: '16px' }}
            onClick={goBack}
          />
          <div>Manajemen Data Peserta Tersier</div>
        </div>
      }
      headerButtons={
        <Space>
          <DownloadRegionCodeButton />
          <DownloadTemplateData programId={programId} />
          <Divider type="vertical" />
          <UploadParticipantDataButton
            programId={programId}
            onSuccess={() => setActiveKey('daftar-antrian')}
          />
        </Space>
      }
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <Tabs
        activeKey={activeKey}
        onChange={(activeKey) => {
          setActiveKey(activeKey)
          trackTabChange(router, activeKey)
        }}
        type="card"
        destroyInactiveTabPane
      >
        <Tabs.TabPane
          key="daftar-peserta"
          tab={
            <>
              <Icons.UnorderedListOutlined className="!mr-1" /> Daftar Peserta
            </>
          }
        >
          <ParticipantTableList programId={programId} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="daftar-antrian"
          tab={
            <>
              <Icons.FieldTimeOutlined className="!mr-1" /> Daftar Antrian
            </>
          }
        >
          <UploadParticipantTableList
            type="upload-tersier"
            programId={programId}
            variant="queue"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="daftar-gagal"
          tab={
            <>
              <Icons.ExclamationCircleOutlined className="!mr-1" /> Daftar Gagal
            </>
          }
        >
          <UploadParticipantTableList
            type="upload-tersier"
            programId={programId}
            variant="failed"
          />
        </Tabs.TabPane>
      </Tabs>
    </List>
  )
}

export default ParticipantManagementList
