import {
  Menu,
  Dropdown,
  Button,
  Icons,
  Space,
  Tag,
  Input,
  Empty,
  Divider,
} from '@pankod/refine-antd'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import {
  useCan,
  useCreate,
  useCustomMutation,
  useInvalidate,
  useNavigation,
  useOne,
  useUpdate,
} from '@pankod/refine-core'
import clsx from 'clsx'

import { DrawerEditGrade } from '@resources/lms-management/program/program-setup/blocks/DrawerEditGrade'
import { CertificateSettingsDrawer } from '../../program-setup/blocks/DrawerCertificateSettings'
import ModulDrawer, {
  useModulDrawer,
} from '@resources/lms-management/program/program-setup/blocks/program-module/ModulDrawer'
import PengajarPraktikConfigDrawer, {
  usePengajarPraktikConfigDrawer,
} from '@resources/lms-management/program/program-setup/blocks/jurnal-pengajar-praktik/PengajarPraktikJurnalDrawer'
import JurnalFasilitasiDrawer, {
  useJurnalFasilitasiDrawer,
} from '@resources/lms-management/program/program-setup/blocks/jurnal-fasilitasi/JurnalFasilitasiDrawer'
import {
  FINALIZATION_CONFIG_STAGE,
  PROGRAM_STATE,
  TLMSProgramDetail,
} from '../../types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import PenilaianKinerjaDrawer, {
  usePenilaianKinerjaDrawer,
} from '../../program-setup/blocks/penilaian-kinerja/PenilaianKinerjaDrawer'
import MenuItemPopconfirm from './MenuItemPopconfirm'
import { TCommonError } from 'src/interfaces/common'
import PresensiDrawer, {
  usePresensiDrawer,
} from '../../program-setup/blocks/presensi/PresensiDrawer'
import PembobotanPenilaianPPGConfigDrawer, {
  usePembobotanPenilaianPPGConfigDrawer,
} from '../../program-setup/blocks/pembobotan-penilaian-pgp/PembobotanPenilaianPGPDrawer'
import BlueprintRegistrationDrawer, {
  useBlueprintRegistrationDrawer,
} from '../../program-setup/blocks/blueprint/BlueprintRegistrationDrawer'
import UmpanBalikProgramDrawer, {
  useUmpanBalikProgramDrawer,
} from '../../program-setup/blocks/umpan-balik-program/UmpanBalikProgramDrawer'
import PeriodePenilaianDrawer, {
  usePeriodePenilaianDrawer,
} from '../../program-setup/blocks/periode-penilaian/PeriodePenilaianDrawer'
import PenilaianKinerjaCategoryDrawer, {
  usePenilaianKinerjaCategoryDrawer,
} from '../../program-setup/blocks/penilaian-kinerja-category/PenilaianKinerjaCategoryDrawer'
import ProgramCopyConfigDrawer, {
  useProgramCopyConfigDrawer,
} from '../../program-setup/blocks/program-copy-config/ProgramCopyConfigDrawer'
import DasusFormPengkiDrawerProps, {
  useDasusFormPengkiDrawer,
} from '../../program-setup/blocks/dasus-form-pengki-config'
import { useProgramStore } from '../../store'
import PaketModulDrawer, {
  usePaketModulConfigDrawer,
} from '../../program-setup/blocks/paket-modul/PaketModulDrawer'
import ActivityListDrawer, {
  useActivityListDrawer,
} from '../../program-setup/blocks/blueprint/ActivityListDrawer'
import ActivityGroupDrawer, {
  useActivityGroupDrawer,
} from '../../program-setup/blocks/activity-group/ActivityGroupDrawer'
import DrawerBuktiKaryaConfig from '../../program-setup/blocks/DrawerBuktiKaryaConfig'
import { Highlighter, filterMenuItemsRecursive } from './utils'
import WebinarDrawer, {
  useWebinarConfigDrawer,
} from '../../program-setup/blocks/webinar/WebinarDrawer'
import useCreateDasusOffline from '../../program-setup/blocks/useCreateDasusOffline'
import SyncClassesButton from '../../program-setup/blocks/SyncClassesButton'
import CertificateParticipantFlagUploadButton from '../CertificateParticipantFlagUploadButton'
import BulkImportParticipantDrawer, {
  useBulkImportParticipantDrawer,
} from '../../program-setup/blocks/bulk-import-participant/BulkImportParticipantDrawer'

import type { InputRef } from 'antd'

const TagElement = ({
  activeText = 'FINALIZED',
  inactiveText = 'DRAFT',
  ...props
}: {
  active: boolean
  activeText?: string
  inactiveText?: string
  className?: string
}) => (
  <Tag
    className={clsx('!m-0', props.className)}
    color={props.active ? 'processing' : 'default'}
  >
    {props.active ? activeText : inactiveText}
  </Tag>
)

type ConfigurationButtonProps = {
  programId?: string | number
  programName?: string
  programStatus?: TLMSProgramDetail['status']
  ppConfigStage?: TLMSProgramDetail['ppConfigStage']
  jurnalFasilitasiConfigStage?: TLMSProgramDetail['jurnalFasilitasiConfigStage']
  penilaianKinerjaConfigStage?: TLMSProgramDetail['penilaianKinerjaConfigStage']
  umpanBalikConfigStage?: TLMSProgramDetail['umpanBalikConfigStage']
  timeTrackerActive?: TLMSProgramDetail['timeTrackerActive']
  disabled?: boolean
  className?: string
  initialSisIdAccount?: string
}
const ConfigurationButton = ({
  programId,
  programName,
  ppConfigStage,
  jurnalFasilitasiConfigStage,
  disabled,
  timeTrackerActive,
  penilaianKinerjaConfigStage,
  umpanBalikConfigStage,
  className,
  programStatus,
  initialSisIdAccount,
}: ConfigurationButtonProps) => {
  const { data } = useOne<{ data: { isActive: boolean } }>({
    dataProviderName: 'lms',
    id: 'flag',
    resource: `programs/${programId}/dummy-participants`,
  })
  const { data: toggleCertificateData } = useOne<{ data: { active: boolean } }>(
    {
      dataProviderName: 'lms',
      id: 'certificate-toggle',
      resource: `programs/${programId}`,
    },
  )
  const { listUrl } = useNavigation()
  const dummyParticipantActive = data?.data?.data?.isActive
  const toggleCertificateActive = toggleCertificateData?.data?.data?.active
  const isOwner = useProgramStore(({ isOwner }) => isOwner)
  const [open, setOpen] = useState(false)
  const [searchConfig, setSearchConfig] = useState('')
  const inputSearchRef = useRef<InputRef>(null)
  const invalidate = useInvalidate()
  const [editGradeShow, setEditGradeShow] = useState(false)
  const [setupCertificateShow, setSetupCertificateShow] = useState(false)
  const [showBKConfig, setShowBKConfig] = useState(false)
  const { mutate: doToggleTimetracker, isLoading: isLoadingTimetracker } =
    useCreate()
  const {
    mutate: doToggleDummyParticipant,
    isLoading: isLoadingDummyParticipant,
  } = useCreate()
  const {
    mutate: doToggleCertificateApproval,
    isLoading: isLoadingCertificateApproval,
  } = useUpdate()
  const { mutateAsync: doResetCertificate } = useCreate()
  const { show: showModulSetup, ...modulDrawerProps } = useModulDrawer()
  const { show: showPresensi, ...presensiDrawerProps } = usePresensiDrawer()
  const { show: showPenilaianKinerja, ...penilaianKinerjaDrawerProps } =
    usePenilaianKinerjaDrawer()
  const {
    show: showPenilaianKinerjaCategory,
    ...penilaianKinerjaCategoryDrawerProps
  } = usePenilaianKinerjaCategoryDrawer()
  const { show: showPP, ...pengajarPraktikDrawerProps } =
    usePengajarPraktikConfigDrawer()
  const { show: showJurnalFasilitasi, ...jurnalFasilitasiDrawerProps } =
    useJurnalFasilitasiDrawer()
  const {
    show: showBlueprintRegistration,
    ...blueprintRegistrationDrawerProps
  } = useBlueprintRegistrationDrawer()
  const {
    show: showPembobotanPenilaianDrawer,
    ...pembobotanPenilaianDrawerProps
  } = usePembobotanPenilaianPPGConfigDrawer()
  const { show: showUmpanBalikProgram, ...umpanBalikProgramDrawerProps } =
    useUmpanBalikProgramDrawer()
  const { show: showPeriodePenilaian, ...periodePenilaianDrawerProps } =
    usePeriodePenilaianDrawer()
  const { show: showProgramCopyConfig, ...programCopyConfigDrawerProps } =
    useProgramCopyConfigDrawer()
  const { show: showPaketModulConfigDrawer, ...paketModulConfigDrawerProps } =
    usePaketModulConfigDrawer()
  const { show: showWebinarConfigDrawer, ...webinarConfigDrawerProps } =
    useWebinarConfigDrawer()
  const {
    show: showBulkImportParticipantDrawer,
    ...bulkImportParticipantDrawerProps
  } = useBulkImportParticipantDrawer()

  const { show: showActivityList, ...activityListDrawerProps } =
    useActivityListDrawer()
  const { show: showActivityGroupList, ...activityGroupListDrawerProps } =
    useActivityGroupDrawer()

  const {
    show: showDasusFormPengkiConfig,
    ...dasusFormPengkiConfigDrawerProps
  } = useDasusFormPengkiDrawer()

  const { mutateAsync: doFinalize } = useCustomMutation<
    {},
    TCommonError,
    void
  >()

  const { doCreate: createProgramDasus, isLoading: isLoadingProgramDasus } =
    useCreateDasusOffline(programId)

  const { data: canRegisterBlueprint } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'blueprint-registration' },
  })

  const { data: canCopyProgramConfig } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'copy-config' },
  })

  const { data: canResetCertificateProgram } = useCan({
    action: 'show',
    resource: 'reset-certificate-program',
  })

  const { data: canDesignCertificateTemplates } = useCan({
    action: 'show',
    resource: 'design-certificate-templates',
  })

  const { data: canToggleCertificateApproval } = useCan({
    action: 'show',
    resource: 'toggle-certificate-approval',
  })

  const { data: canAccessWebinar } = useCan({
    action: 'show',
    resource: 'lms-webinar-management',
  })

  const { data: canConfigureOfflineDasus } = useCan({
    action: 'show',
    resource: 'dasus-management',
  })

  const { data: canSyncClass } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'sync-classes' },
  })

  const { data: canUpdateParticipantCertificateFlag } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'certificate-participant-flag' },
  })

  const router = useRouter()
  const isPPFinalized = ppConfigStage === FINALIZATION_CONFIG_STAGE.FINALIZED
  const isFasilitasiFinalized =
    jurnalFasilitasiConfigStage === FINALIZATION_CONFIG_STAGE.FINALIZED
  const isPenilaianKinerjaFinalized =
    penilaianKinerjaConfigStage === FINALIZATION_CONFIG_STAGE.FINALIZED
  const isUmpanBalikFinalized =
    umpanBalikConfigStage === FINALIZATION_CONFIG_STAGE.FINALIZED

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setTimeout(() => {
        inputSearchRef.current?.focus()
      }, 0)
    } else {
      setSearchConfig('')
    }
    setOpen(open)
  }

  const handleMenuClick = async (
    callback: () => void | Promise<any>,
    shouldClose: boolean = true,
  ) => {
    await callback()
    setOpen(!shouldClose)
    setSearchConfig('')
  }

  const invalidateProgramDetail = () =>
    invalidate({
      invalidates: ['detail'],
      dataProviderName: 'lms',
      resource: 'programs',
      id: programId,
    })

  const finalizeConfig = async (
    type:
      | 'pengajarPraktik'
      | 'fasilitasi'
      | 'penilaianKinerja'
      | 'umpanBalik'
      | 'periodePenilaian',
  ) => {
    const dataByType = {
      pengajarPraktik: {
        title: 'Jurnal pengajar praktik',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/pp/${programId}/finalize-config`,
      },
      fasilitasi: {
        title: 'Jurnal fasilitasi',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/jurnal-fasilitasi/finalize-config`,
      },
      penilaianKinerja: {
        title: 'Penilaian kinerja',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/penilaian-kinerja/finalize-config`,
      },
      umpanBalik: {
        title: 'Umpan Balik',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/umpan-balik/finalize-config`,
      },
      periodePenilaian: {
        title: 'Periode Penilaian',
        url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/periode-penilaian/finalize-config`,
      },
    }
    await doFinalize(
      {
        method: 'post',
        url: dataByType[type].url,
        values: undefined,
        successNotification: {
          message: `${dataByType[type].title} telah difinalisasi`,
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (error: any) =>
          showErrorNotification(
            error,
            `There was a problem when finalizing ${dataByType[type].title}`,
          ),
      },
      {
        onSuccess: () => {
          invalidateProgramDetail()
        },
      },
    )
    handleOpenChange(false)
  }

  const menuItems = [
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Nilai (Grading)" />,
      key: 'Nilai (Grading)',
      children: [
        {
          icon: <Icons.ProjectOutlined />,
          label: <Highlighter search={searchConfig} text="Pengaturan Nilai" />,
          key: 'Pengaturan Nilai',
          onClick: () => handleMenuClick(() => setEditGradeShow(true)),
        },
        {
          icon: <Icons.EyeOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Lihat Laporan Nilai" />
          ),
          key: 'Lihat Laporan Nilai',
          onClick: () =>
            handleMenuClick(() =>
              router.push(`/grade-report?programId=${programId}`),
            ),
        },
      ],
    },
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Program Modul" />,
      key: 'Program Modul',
      children: [
        {
          icon: <Icons.BookOutlined />,
          label: <Highlighter search={searchConfig} text="Pengaturan Modul" />,
          key: 'Pengaturan Modul',
          onClick: () =>
            handleMenuClick(() => showModulSetup(programId!, programName!)),
        },
        {
          icon: <Icons.BuildFilled />,
          label: (
            <Highlighter search={searchConfig} text="Kelola Paket Modul" />
          ),
          key: 'Kelola Paket Modul',
          onClick: () =>
            handleMenuClick(() => showPaketModulConfigDrawer(programId!)),
        },
        {
          icon: <Icons.AppstoreOutlined />,
          label: (
            <Highlighter
              search={searchConfig}
              text="Kelola Kelompok Aktivitas"
            />
          ),
          key: 'Kelola Kelompok Aktivitas',
          onClick: () =>
            handleMenuClick(() =>
              showActivityGroupList(programId!, programStatus),
            ),
        },
        {
          icon: <Icons.CarryOutOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Pengaturan Presensi" />
          ),
          key: 'Pengaturan Presensi',
          onClick: () =>
            handleMenuClick(() => showPresensi(programId!, programName!)),
        },
      ],
    },
    {
      type: 'group',
      key: 'Jurnal Pengajar Praktik',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Jurnal Pengajar Praktik" />
          <TagElement active={!!isPPFinalized} />
        </div>
      ),
      children: [
        {
          icon: <Icons.TeamOutlined />,
          label: <Highlighter search={searchConfig} text="Kelola Jurnal PP" />,
          key: 'Kelola Jurnal PP',
          onClick: () => handleMenuClick(() => showPP(programId!)),
        },
        {
          icon: <Icons.LockOutlined />,
          label: (
            <MenuItemPopconfirm
              title="Apakah Anda yakin ingin melakukan finalisasi Jurnal Pengajar Praktik?"
              disabled={isPPFinalized}
              onConfirm={async () => {
                await finalizeConfig('pengajarPraktik')
              }}
              placement="right"
              okText="Finalisasi"
              cancelText="Batal"
            >
              <Highlighter search={searchConfig} text="Finalisasi Jurnal PP" />
            </MenuItemPopconfirm>
          ),
          key: 'Finalisasi Jurnal PP',
          disabled: isPPFinalized,
        },
      ],
    },
    {
      type: 'group',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Jurnal Fasilitasi" />
          <TagElement active={!!isFasilitasiFinalized} />
        </div>
      ),
      key: 'Jurnal Fasilitasi',
      children: [
        {
          icon: <Icons.ToolOutlined />,
          label: (
            <Highlighter
              search={searchConfig}
              text="Kelola Jurnal Fasilitasi"
            />
          ),
          key: 'Kelola Jurnal Fasilitasi',
          onClick: () =>
            handleMenuClick(() =>
              showJurnalFasilitasi(programId!, programName!),
            ),
        },
        {
          icon: <Icons.LockOutlined />,
          label: (
            <MenuItemPopconfirm
              title="Apakah Anda yakin ingin melakukan finalisasi Jurnal Fasilitasi?"
              disabled={isFasilitasiFinalized}
              onConfirm={async () => {
                await finalizeConfig('fasilitasi')
              }}
              placement="right"
              okText="Finalisasi"
              cancelText="Batal"
            >
              <Highlighter
                search={searchConfig}
                text="Finalisasi Jurnal Fasilitasi"
              />
            </MenuItemPopconfirm>
          ),
          key: 'Finalisasi Jurnal Fasilitasi',
          disabled: isFasilitasiFinalized,
        },
      ],
    },
    {
      type: 'group',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Penilaian Kinerja" />
          <TagElement active={!!isPenilaianKinerjaFinalized} />
        </div>
      ),
      key: 'Penilaian Kinerja',
      children: [
        {
          icon: <Icons.PartitionOutlined />,
          label: (
            <Highlighter
              search={searchConfig}
              text="Kategori Penilaian Kinerja"
            />
          ),
          key: 'Kategori Penilaian Kinerja',
          onClick: () => {
            handleMenuClick(() =>
              showPenilaianKinerjaCategory(programId!, programName!),
            )
          },
        },
        {
          icon: <Icons.BarChartOutlined />,
          label: (
            <Highlighter
              search={searchConfig}
              text="Kelola Penilaian Kinerja"
            />
          ),
          key: 'Kelola Penilaian Kinerja',
          onClick: () =>
            handleMenuClick(() =>
              showPenilaianKinerja(programId!, programName!),
            ),
        },
        {
          icon: <Icons.LockOutlined />,
          label: (
            <MenuItemPopconfirm
              title="Apakah Anda yakin ingin melakukan finalisasi Penilaian Kinerja?"
              disabled={isPenilaianKinerjaFinalized}
              onConfirm={async () => {
                await finalizeConfig('penilaianKinerja')
              }}
              placement="right"
              okText="Finalisasi"
              cancelText="Batal"
            >
              <Highlighter
                search={searchConfig}
                text="Finalisasi Penilaian Kinerja"
              />
            </MenuItemPopconfirm>
          ),
          key: 'Finalisasi Penilaian Kinerja',
          disabled: isPenilaianKinerjaFinalized,
        },
      ],
    },
    {
      type: 'group',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Umpan Balik Program" />
          <TagElement active={!!isUmpanBalikFinalized} />
        </div>
      ),
      key: 'Umpan Balik Program',
      children: [
        {
          icon: <Icons.CommentOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Kelola Umpan Balik" />
          ),
          key: 'Kelola Umpan Balik',
          onClick: () =>
            handleMenuClick(() =>
              showUmpanBalikProgram(programId!, programName!),
            ),
        },
        {
          icon: <Icons.LockOutlined />,
          label: (
            <MenuItemPopconfirm
              title="Apakah Anda yakin ingin melakukan finalisasi Umpan Balik Program?"
              disabled={isUmpanBalikFinalized}
              onConfirm={async () => {
                await finalizeConfig('umpanBalik')
              }}
              placement="right"
              okText="Finalisasi"
              cancelText="Batal"
            >
              <Highlighter
                search={searchConfig}
                text="Finalisasi Umpan Balik"
              />
            </MenuItemPopconfirm>
          ),
          key: 'Finalisasi Umpan Balik',
          disabled: isUmpanBalikFinalized,
        },
      ],
    },
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Sertifikat" />,
      key: 'Sertifikat',
      children: [
        ...(canDesignCertificateTemplates?.can || isOwner
          ? [
              {
                icon: <Icons.PictureOutlined />,
                label: (
                  <Highlighter
                    search={searchConfig}
                    text="Kelola Design Template"
                  />
                ),
                key: 'Kelola Design Template',
                onClick: () =>
                  handleMenuClick(() => {
                    const certificateTempalateUrl = listUrl(
                      'certificate-templates',
                    )
                    router.push(
                      `${certificateTempalateUrl}?programId=${programId}`,
                    )
                  }),
              },
            ]
          : []),
        {
          icon: <Icons.FileDoneOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Pengaturan Sertifikat" />
          ),
          key: 'Pengaturan Sertifikat',
          onClick: () => handleMenuClick(() => setSetupCertificateShow(true)),
        },
        ...(canResetCertificateProgram?.can || isOwner
          ? [
              {
                icon: <Icons.FileSyncOutlined />,
                danger: true,
                label: (
                  <MenuItemPopconfirm
                    title="Apakah Anda yakin ingin melakukan reset seluruh sertifikat pada program ini?"
                    onConfirm={async () => {
                      return await doResetCertificate({
                        dataProviderName: 'lms',
                        values: {},
                        resource: `v2/programs/${programId}/reset-certificate`,
                        successNotification: {
                          message: 'Sertifikat berhasil direset',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (e) =>
                          showErrorNotification(
                            e,
                            'Terdapat gangguan saat reset sertifikat',
                          ),
                      })
                    }}
                    placement="right"
                    okText="Reset"
                    okButtonProps={{ danger: true }}
                    cancelText="Batal"
                  >
                    <Highlighter
                      search={searchConfig}
                      text="Reset Sertifikat"
                    />
                  </MenuItemPopconfirm>
                ),
                key: 'Reset Sertifikat',
              },
            ]
          : []),
        ...(canToggleCertificateApproval?.can || isOwner
          ? [
              {
                key: 'Pengabsahan',
                type: 'group',
                label: (
                  <div className="flex justify-between">
                    <Highlighter search={searchConfig} text="Pengabsahan" />
                    <TagElement
                      active={!!toggleCertificateActive}
                      activeText="AKTIF"
                      inactiveText="NONAKTIF"
                      className="!-mr-2"
                    />
                  </div>
                ),
                children: [
                  {
                    icon: isLoadingCertificateApproval ? (
                      <Icons.LoadingOutlined spin />
                    ) : toggleCertificateActive ? (
                      <Icons.StopOutlined />
                    ) : (
                      <Icons.FileProtectOutlined />
                    ),
                    key: toggleCertificateActive
                      ? 'Nonaktifkan Pengabsahan (Approval)'
                      : 'Aktifkan Pengabsahan (Approval)',
                    danger: toggleCertificateActive,
                    disabled: isLoadingCertificateApproval,
                    label: (
                      <MenuItemPopconfirm
                        title={`Apakah Anda ingin ${
                          toggleCertificateActive
                            ? 'me-nonaktifkan'
                            : 'mengaktifkan'
                        } pengabsahan (approval) sertifikat?`}
                        okText={
                          toggleCertificateActive ? 'Nonaktifkan' : 'Aktifkan'
                        }
                        cancelText="Batal"
                        disabled={isLoadingCertificateApproval}
                        okButtonProps={{ danger: toggleCertificateActive }}
                        onConfirm={() =>
                          doToggleCertificateApproval({
                            resource: `programs/${programId}`,
                            dataProviderName: 'lms',
                            id: 'certificate-toggle',
                            values: {
                              active: !toggleCertificateActive,
                            },
                            successNotification: {
                              message: `Pengabsahan sertifikat berhasil dikonfigurasi ulang`,
                              type: 'success',
                              description: 'Sukses',
                            },
                            errorNotification: (err) =>
                              showErrorNotification(
                                err,
                                'Terdapat gangguan saat mengubah konfigurasi pengabsahan sertifikat',
                              ),
                          })
                        }
                      >
                        {toggleCertificateActive ? (
                          <Highlighter
                            search={searchConfig}
                            text="Nonaktifkan Pengabsahan (Approval)"
                          />
                        ) : (
                          <Highlighter
                            search={searchConfig}
                            text="Aktifkan Pengabsahan (Approval)"
                          />
                        )}
                      </MenuItemPopconfirm>
                    ),
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      type: 'group',
      label: (
        <Highlighter search={searchConfig} text="Pembobotan & Penilaian PGP" />
      ),
      key: 'Pembobotan & Penilaian PGP',
      children: [
        {
          icon: <Icons.PercentageOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Pembobotan Nilai PGP" />
          ),
          key: 'Pembobotan Nilai PGP',
          onClick: () =>
            handleMenuClick(() => showPembobotanPenilaianDrawer(programId!)),
        },
      ],
    },
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Manajemen Peserta" />,
      key: 'Manajemen Peserta',
      children: [
        {
          icon: <Icons.UserSwitchOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Data Peserta Tersier" />
          ),
          key: 'Data Peserta Tersier',
          onClick: () =>
            handleMenuClick(() =>
              router.push(`/participant-management?programId=${programId}`),
            ),
        },
      ],
    },
    {
      type: 'group',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Time Tracker" />
          <TagElement
            active={!!timeTrackerActive}
            activeText="AKTIF"
            inactiveText="NONAKTIF"
          />
        </div>
      ),
      key: 'Time Tracker',
      children: [
        {
          icon: isLoadingTimetracker ? (
            <Icons.LoadingOutlined spin />
          ) : timeTrackerActive ? (
            <Icons.StopOutlined />
          ) : (
            <Icons.FieldTimeOutlined />
          ),
          key: timeTrackerActive
            ? 'Nonaktifkan Time Tracker'
            : 'Aktifkan Time Tracker',
          danger: timeTrackerActive,
          disabled: isLoadingTimetracker,
          label: (
            <MenuItemPopconfirm
              title={`Apakah Anda ingin ${
                timeTrackerActive ? 'me-nonaktifkan' : 'mengaktifkan'
              } time tracker?`}
              okText={timeTrackerActive ? 'Nonaktifkan' : 'Aktifkan'}
              cancelText="Batal"
              disabled={isLoadingTimetracker}
              okButtonProps={{ danger: timeTrackerActive }}
              onConfirm={() =>
                doToggleTimetracker(
                  {
                    resource: `programs/${programId}/time-tracker`,
                    dataProviderName: 'lms',
                    values: {
                      active: !timeTrackerActive,
                    },
                    successNotification: {
                      message: `Time Tracker berhasil dikonfigurasi ulang`,
                      type: 'success',
                      description: 'Sukses',
                    },
                    errorNotification: (err) =>
                      showErrorNotification(
                        err,
                        'Terdapat gangguan saat mengubah konfigurasi time tracker',
                      ),
                  },
                  {
                    onSuccess: () => {
                      invalidateProgramDetail()
                    },
                  },
                )
              }
            >
              {timeTrackerActive ? (
                <Highlighter
                  search={searchConfig}
                  text="Nonaktifkan Time Tracker"
                />
              ) : (
                <Highlighter
                  search={searchConfig}
                  text="Aktifkan Time Tracker"
                />
              )}
            </MenuItemPopconfirm>
          ),
        },
        {
          key: 'Periode Penilaian',
          type: 'group',
          label: <Highlighter search={searchConfig} text="Periode Penilaian" />,
          children: [
            {
              key: 'Kelola Periode',
              label: (
                <Highlighter search={searchConfig} text="Kelola Periode" />
              ),
              icon: <Icons.ClockCircleOutlined />,
              onClick: () =>
                handleMenuClick(() =>
                  showPeriodePenilaian(programId!, programName!),
                ),
            },
          ],
        },
      ],
    },
    ...((canRegisterBlueprint?.can || isOwner) &&
    programStatus === PROGRAM_STATE.PREPARATION
      ? [
          {
            type: 'group',
            label: <Highlighter search={searchConfig} text="Blueprint" />,
            key: 'Blueprint',
            children: [
              {
                icon: <Icons.BlockOutlined />,
                label: (
                  <Highlighter
                    search={searchConfig}
                    text="Registrasi Blueprint"
                  />
                ),
                key: 'Registrasi Blueprint',
                onClick: () =>
                  handleMenuClick(() => showBlueprintRegistration(programId!)),
              },
              {
                icon: <Icons.UnorderedListOutlined />,
                label: (
                  <Highlighter
                    search={searchConfig}
                    text="Daftar Aktivitas Canvas"
                  />
                ),
                key: 'Daftar Aktivitas Canvas',
                onClick: () =>
                  handleMenuClick(() =>
                    showActivityList(programId!, programName!),
                  ),
              },
            ],
          },
        ]
      : []),
    ...(canCopyProgramConfig?.can
      ? [
          {
            type: 'group',
            label: <Highlighter search={searchConfig} text="Program Copy" />,
            key: 'Program Copy',
            children: [
              {
                icon: <Icons.CopyOutlined />,
                label: (
                  <Highlighter
                    search={searchConfig}
                    text="Salin Konfigurasi Program"
                  />
                ),
                key: 'Salin Konfigurasi Program',
                onClick: () => {
                  handleMenuClick(() =>
                    showProgramCopyConfig(programId!, programName!),
                  )
                },
              },
            ],
          },
        ]
      : []),
    {
      type: 'group',
      label: (
        <Highlighter
          search={searchConfig}
          text="Penilaian Kinerja Tidak Langsung"
        />
      ),
      key: 'Penilaian Kinerja Tidak Langsung',
      children: [
        {
          icon: <Icons.FormOutlined />,
          label: (
            <Highlighter
              search={searchConfig}
              text="Form Penilaian Kinerja Dasus"
            />
          ),
          key: 'Form Penilaian Kinerja Dasus',
          onClick: () => {
            handleMenuClick(() =>
              showDasusFormPengkiConfig(programId!, programName!),
            )
          },
        },
      ],
    },
    {
      key: 'Dummy Peserta',
      type: 'group',
      label: (
        <div className="flex justify-between">
          <Highlighter search={searchConfig} text="Dummy Peserta" />
          <TagElement
            active={!!dummyParticipantActive}
            activeText="AKTIF"
            inactiveText="NONAKTIF"
          />
        </div>
      ),
      children: [
        {
          icon: isLoadingDummyParticipant ? (
            <Icons.LoadingOutlined spin />
          ) : dummyParticipantActive ? (
            <Icons.StopOutlined />
          ) : (
            <Icons.RobotOutlined />
          ),
          key: dummyParticipantActive
            ? 'Nonaktifkan Dummy Peserta'
            : 'Aktifkan Dummy Peserta',
          danger: dummyParticipantActive,
          disabled: isLoadingDummyParticipant,
          label: (
            <MenuItemPopconfirm
              title={`Apakah Anda ingin ${
                dummyParticipantActive ? 'me-nonaktifkan' : 'mengaktifkan'
              } dummy peserta?`}
              okText={dummyParticipantActive ? 'Nonaktifkan' : 'Aktifkan'}
              cancelText="Batal"
              disabled={isLoadingDummyParticipant}
              okButtonProps={{ danger: dummyParticipantActive }}
              onConfirm={() =>
                doToggleDummyParticipant(
                  {
                    resource: `programs/${programId}/dummy-participants/flag`,
                    dataProviderName: 'lms',
                    values: {
                      isActive: !dummyParticipantActive,
                    },
                    successNotification: {
                      message: `Dummy Peserta berhasil dikonfigurasi ulang`,
                      type: 'success',
                      description: 'Sukses',
                    },
                    errorNotification: (err) =>
                      showErrorNotification(
                        err,
                        'Terdapat gangguan saat mengubah konfigurasi dummy peserta',
                      ),
                  },
                  {
                    onSuccess: () => {
                      invalidate({
                        invalidates: ['detail'],
                        dataProviderName: 'lms',
                        resource: `programs/${programId}/dummy-participants`,
                        id: 'flag',
                      })
                    },
                  },
                )
              }
            >
              {dummyParticipantActive ? (
                <Highlighter
                  search={searchConfig}
                  text="Nonaktifkan Dummy Peserta"
                />
              ) : (
                <Highlighter
                  search={searchConfig}
                  text="Aktifkan Dummy Peserta"
                />
              )}
            </MenuItemPopconfirm>
          ),
        },
      ],
    },
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Bukti Karya" />,
      key: 'Bukti Karya',
      children: [
        {
          icon: <Icons.SettingOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Konfigurasi Bukti Karya" />
          ),
          key: 'Konfigurasi Bukti Karya',
          onClick: () => handleMenuClick(() => setShowBKConfig(true)),
        },
      ],
    },
    ...(canAccessWebinar?.can || isOwner
      ? [
          {
            icon: <Icons.VideoCameraOutlined />,
            label: <Highlighter search={searchConfig} text="Kelola Webinar" />,
            key: 'Kelola Webinar',
            onClick: () =>
              handleMenuClick(() => showWebinarConfigDrawer(programId!)),
          },
        ]
      : []),
    ...(canConfigureOfflineDasus?.can || isOwner
      ? [
          {
            icon: <Icons.SettingOutlined />,
            label: (
              <Highlighter
                search={searchConfig}
                text="Konfigurasi Program Dasus"
              />
            ),
            key: 'Konfigurasi Program Dasus',
            onClick: () => handleMenuClick(() => createProgramDasus()),
            disabled: isLoadingProgramDasus,
          },
        ]
      : []),
    {
      type: 'group',
      label: <Highlighter search={searchConfig} text="Konfigurasi Lainnya" />,
      key: 'Konfigurasi Lainnya',
      children: [
        {
          icon: <Icons.SyncOutlined />,
          label: (
            <SyncClassesButton disabled={!(canSyncClass?.can || isOwner)}>
              <Highlighter search={searchConfig} text="Sinkronisasi Kelas" />
            </SyncClassesButton>
          ),
          key: 'Sinkronisasi Kelas',
          onClick: () => {},
          disabled: !(canSyncClass?.can || isOwner),
        },
        {
          icon: <Icons.FlagOutlined />,
          label: (
            <CertificateParticipantFlagUploadButton
              disabled={!canUpdateParticipantCertificateFlag?.can}
            >
              <Highlighter
                search={searchConfig}
                text="Unggah CSV Flag Sertifikat"
              />
            </CertificateParticipantFlagUploadButton>
          ),
          key: 'Unggah CSV Flag Sertifikat',
          onClick: () => {},
          disabled: !canUpdateParticipantCertificateFlag?.can,
        },
        {
          icon: <Icons.UsergroupAddOutlined />,
          label: (
            <Highlighter search={searchConfig} text="Bulk Import Partisipan" />
          ),
          key: 'Bulk Import Partisipan',
          onClick: () => {
            handleMenuClick(() => {
              showBulkImportParticipantDrawer(programId!)
            })
          },
        },
      ],
    },
  ].toSorted((a, b) =>
    a.key === 'Konfigurasi Lainnya' ? 1 : a.key.localeCompare(b.key),
  )

  const filteredMenuItems = filterMenuItemsRecursive(menuItems, searchConfig)
  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={
          <div className="dropdown-search flex flex-col">
            <Input.Search
              placeholder="Cari menu konfigurasi"
              ref={inputSearchRef}
              autoFocus
              className="!w-[calc(100%_-_32px)] mt-3 mx-4"
              value={searchConfig}
              allowClear
              onChange={(e) => {
                setSearchConfig(e.target.value)
              }}
            />
            <Divider className="!my-3 !mb-0" />
            {filteredMenuItems.length ? (
              <Menu
                className="flex flex-col w-fit overflow-auto max-h-80 gap-3 !pb-3 !px-1 !shadow-none"
                items={filteredMenuItems}
              />
            ) : (
              <Empty
                description="Tidak ada konfigurasi"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </div>
        }
        overlayClassName="!z-[1000] w-fit max-h-[525px]"
        disabled={disabled}
        visible={open}
        onVisibleChange={handleOpenChange}
      >
        <Button
          icon={<Icons.SettingOutlined className="mr-2" />}
          className={className}
        >
          <Space>
            Konfigurasi Program
            <Icons.DownOutlined
              className={clsx(
                open && '-rotate-180',
                !open && '!delay-100',
                '!transition-transform',
              )}
            />
          </Space>
        </Button>
      </Dropdown>
      <DrawerEditGrade
        onDrawerClose={() => setEditGradeShow(false)}
        onSubmitted={() => setEditGradeShow(false)}
        open={editGradeShow}
        programName={programName}
        selectedId={Number(programId)}
      />
      <CertificateSettingsDrawer
        programId={programId}
        programName={programName}
        visible={setupCertificateShow}
        onClose={() => setSetupCertificateShow(false)}
      />
      <ModulDrawer {...modulDrawerProps} programStatus={programStatus} />
      <PenilaianKinerjaDrawer
        {...penilaianKinerjaDrawerProps}
        isFinalized={isPenilaianKinerjaFinalized}
      />
      <PengajarPraktikConfigDrawer
        {...pengajarPraktikDrawerProps}
        isFinalized={isPPFinalized}
      />
      <UmpanBalikProgramDrawer
        {...umpanBalikProgramDrawerProps}
        isFinalized={isUmpanBalikFinalized}
        programStatus={programStatus}
      />
      <JurnalFasilitasiDrawer {...jurnalFasilitasiDrawerProps} />
      <PresensiDrawer {...presensiDrawerProps} programStatus={programStatus} />
      <PembobotanPenilaianPPGConfigDrawer {...pembobotanPenilaianDrawerProps} />
      <BlueprintRegistrationDrawer
        {...blueprintRegistrationDrawerProps}
        initialSisIdAccount={initialSisIdAccount}
      />
      <PeriodePenilaianDrawer {...periodePenilaianDrawerProps} />
      <PenilaianKinerjaCategoryDrawer
        {...penilaianKinerjaCategoryDrawerProps}
      />
      <ProgramCopyConfigDrawer {...programCopyConfigDrawerProps} />
      <DasusFormPengkiDrawerProps {...dasusFormPengkiConfigDrawerProps} />
      <PaketModulDrawer {...paketModulConfigDrawerProps} />
      <ActivityListDrawer {...activityListDrawerProps} />
      <ActivityGroupDrawer {...activityGroupListDrawerProps} />
      <DrawerBuktiKaryaConfig
        programId={programId}
        show={showBKConfig}
        setShow={setShowBKConfig}
      />
      <WebinarDrawer {...webinarConfigDrawerProps} />
      <BulkImportParticipantDrawer {...bulkImportParticipantDrawerProps} />
    </>
  )
}

export default ConfigurationButton
