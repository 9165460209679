import { Drawer, Tabs } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useInvalidate } from '@pankod/refine-core'

import UploadParticipantTableList from '@resources/lms-management/participant-management/blocks/UploadParticipantTableList'
import UploadParticipantDataButton from './UploadParticipantDataButton'

export const useBulkImportParticipantDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    programId: showId.programId,
    onClose,
  }
}

type BulkImportParticipantDrawer = {
  visible: boolean
  onClose: () => void
  programId?: string | number | string[]
}

const BulkImportParticipantDrawer = ({
  onClose,
  visible,
  programId,
}: BulkImportParticipantDrawer) => {
  const invalidate = useInvalidate()
  return (
    <Drawer
      title="Bulk Import Partisipan ke Program"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      maskClosable={false}
      width="70%"
      extra={
        <UploadParticipantDataButton
          programId={programId}
          onSuccess={() => {
            invalidate({
              resource: `programs/${programId}/participant-queue`,
              dataProviderName: 'lms',
              invalidates: ['list'],
            })
          }}
        />
      }
    >
      <Tabs type="card" destroyInactiveTabPane>
        <Tabs.TabPane key="queue" tab="Daftar Antrian Import">
          <UploadParticipantTableList
            type="program-enrollment"
            variant="queue"
            programId={programId}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="failed" tab="Daftar Gagal Import">
          <UploadParticipantTableList
            type="program-enrollment"
            variant="failed"
            programId={programId}
          />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}

export default BulkImportParticipantDrawer
