import React from 'react'
import { Canvas } from 'fabric'
import { Button, Empty } from '@pankod/refine-antd'
import { FileCode2, FileType2 } from 'lucide-react'

import { addText } from '../../utils/text'
import { CertificateTemplateVariables } from '@resources/lms-management/certificate-template/types'
import { QR_CODE_CERTIFICATE_KEY } from '../../constants'
import { addQRCode } from '../../utils/image'

type ActorComponents = {
  canvas: Canvas | null
  variable: CertificateTemplateVariables['systemVariable']
}

const ActorComponents = (props: ActorComponents) => {
  const handleAddComponent = (value: string) => {
    if (value === QR_CODE_CERTIFICATE_KEY) {
      addQRCode(props.canvas)
      return
    }
    addText({
      canvas: props.canvas,
      value: `{{ ${value} }}`,
      customVariableKey: value,
    })
  }
  return (
    <>
      <div className="grid gap-2 grid-flow-row h-full overflow-auto">
        {!props.variable.length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Tidak ada komponen"
          />
        )}
        {props.variable.map((key, i) => (
          <Button
            key={key + i}
            onClick={() => {
              handleAddComponent(key)
            }}
            icon={
              key === QR_CODE_CERTIFICATE_KEY ? (
                <FileCode2 className="h-5 w-5" />
              ) : (
                <FileType2 className="h-5 w-5" />
              )
            }
            className="!flex gap-2 items-center justify-center font-mono"
            block
            title={key}
          >
            <div className="max-w-[150px] overflow-hidden text-ellipsis">
              {key}
            </div>
          </Button>
        ))}
      </div>
    </>
  )
}

export default ActorComponents
