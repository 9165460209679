import {
  Button,
  Card,
  Cascader,
  Descriptions,
  Empty,
  Form,
  Icons,
  Modal,
  Space,
  Table,
  Tag,
  TextField,
  Typography,
} from '@pankod/refine-antd'
import { useCan, useList, useOne } from '@pankod/refine-core'
import { useState } from 'react'

import trimDecimalString from 'utils/trimDecimalString'
import useGetACL from 'src/hooks/useGetACL'
import { useCascaderLoadData } from 'utils/hooks'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import ExportReportButton from '@components/ExportReportButton'
import { useProgramStore } from '../store'

type TabNilaiAkhirProps = {
  programId: string | number | undefined
}

const TabNilaiAkhir = ({ programId }: TabNilaiAkhirProps) => {
  const { loadData, options, setOptions } = useCascaderLoadData({
    resourceUrl: (id) =>
      `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/local-programs/${id}/classes`,
  })
  const [, , lmsRole] = useGetACL()
  const { isFetching: fetchingLocalProgram } = useList({
    resource:
      lmsRole !== 'admin-entitas'
        ? `programs/${programId}/local-programs`
        : `entities/programs/${programId}/local-programs`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!lmsRole,
      onSuccess(data) {
        setOptions(
          data?.data?.map(({ id, name }) => ({
            label: name,
            value: id,
            isLeaf: false,
          })),
        )
      },
    },
    config: {
      filters: [{ field: 'pageSize', operator: 'eq', value: -1 }],
    },
  })

  const { data: canExport } = useCan({
    action: 'field',
    resource: 'nilai-akhir',
    params: {
      field: 'export',
    },
  })

  const { tableProps, setFilters } = useTableWithMeta({
    syncWithLocation: false,
    resource: `program/${programId}/nilai-akhir`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
    defaultSetFilterBehavior: 'replace',
  })

  const [selectedUserId, setSelectedUserId] = useState<any>('')
  const isOwner = useProgramStore(({ isOwner }) => isOwner)
  const { data: userScoreDetail } = useOne({
    resource: `program/${programId}/nilai-akhir`,
    id: selectedUserId.userId,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!selectedUserId.userId,
    },
  })

  const PenilaianDescriptionItem = (penilaian: any) => {
    return (
      <Descriptions.Item
        label={
          <p>
            {penilaian.title}
            <span className="ml-4 font-extrabold">
              ({penilaian.weightPercentage}%)
            </span>
          </p>
        }
      >
        <p>
          <span className="font-extrabold">
            {trimDecimalString(penilaian.finalScore)} point
          </span>

          <span className="ml-2 font-extralight text-xs text-disabled ">
            ({penilaian.score} / {penilaian.maxScore})
          </span>
        </p>
      </Descriptions.Item>
    )
  }

  const LokaKaryaDescriptionItem = (lokaKarya: any) => {
    return (
      <>
        <Descriptions.Item
          label={<p className="font-extrabold mt-4">{lokaKarya.title}</p>}
        >
          {' '}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <p className="gap-4">
              {lokaKarya.kehadiran.title}{' '}
              <span className="ml-4 font-extrabold">
                ({lokaKarya.kehadiran.weightPercentage}%)
              </span>
            </p>
          }
        >
          <p>
            <span className="font-extrabold">
              {trimDecimalString(lokaKarya.kehadiran.finalScore)} point
            </span>

            <span className="ml-2 font-extralight text-xs text-disabled ">
              ({lokaKarya.kehadiran.score} / {lokaKarya.kehadiran.maxScore})
            </span>
          </p>
        </Descriptions.Item>

        {lokaKarya.penilaian.map((penilaian: any) => (
          <>{PenilaianDescriptionItem(penilaian)}</>
        ))}
      </>
    )
  }

  const PendampinganIndividuDescriptionItem = (pendampinganIndividu: any) => {
    return (
      <>
        <Descriptions.Item
          label={
            <p className="font-extrabold mt-4">{pendampinganIndividu.title}</p>
          }
        >
          {' '}
        </Descriptions.Item>

        {pendampinganIndividu.penilaian.map((penilaian: any) => (
          <>{PenilaianDescriptionItem(penilaian)}</>
        ))}
      </>
    )
  }

  const ModuleDescriptionItem = (module: any) => {
    return (
      <>
        <Descriptions.Item
          label={<p className="font-extrabold mt-4">{module.title}</p>}
        >
          {' '}
        </Descriptions.Item>

        {module.aktivitas.map((penilaian: any) => (
          <>{PenilaianDescriptionItem(penilaian)}</>
        ))}
      </>
    )
  }

  const DetailScoreItemDescription = (item: any, name: string) => {
    return (
      <>
        <Descriptions.Item
          label={<h3 className="font-extrabold mt-8">{name}</h3>}
        >
          {''}
        </Descriptions.Item>

        {item.lokaKarya &&
          item.lokaKarya.map((lokaKarya: any) => (
            <>{LokaKaryaDescriptionItem(lokaKarya)}</>
          ))}
        {item.pendampinganIndividu &&
          item.pendampinganIndividu.map((pendampinganIndividu: any) => (
            <>{PendampinganIndividuDescriptionItem(pendampinganIndividu)}</>
          ))}

        {item.module &&
          item.module.map((module: any) => (
            <>{ModuleDescriptionItem(module)}</>
          ))}

        <Descriptions.Item
          label={<p className="font-extrabold text-blue-70">Total</p>}
        >
          <p className="font-extrabold text-blue-70">
            {trimDecimalString(item.totalScore)} Point
          </p>
        </Descriptions.Item>
      </>
    )
  }

  return (
    <div>
      <div className="flex gap-3 mb-1">
        <Typography.Title level={5}>Daftar Nilai Akhir</Typography.Title>
        <ExportReportButton
          can={canExport?.can || isOwner}
          fileName={`NilaiAkhir_ProgramId_${programId}`}
          url={`${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/export-nilai-akhir`}
          size="small"
        />
      </div>
      <Card className="!mb-3" size="small">
        <Form layout="vertical">
          <Form.Item
            className="!mb-0"
            label="Filter berdasarkan Program Lokal atau Kelas"
          >
            <Cascader
              loading={fetchingLocalProgram}
              options={options}
              placeholder="Pilih Program Lokal / Kelas"
              changeOnSelect
              expandTrigger="hover"
              loadData={loadData}
              onChange={(value) => {
                if (!value) {
                  setFilters([{ field: 'page', operator: 'eq', value: 1 }])
                  return
                }
                const [programLocalId, kelasId] = value
                if (kelasId) {
                  setFilters([
                    {
                      field: 'kelasId',
                      operator: 'eq',
                      value: kelasId,
                    },
                    { field: 'page', operator: 'eq', value: 1 },
                  ])
                  return
                }
                if (programLocalId) {
                  setFilters([
                    {
                      field: 'programLocalId',
                      operator: 'eq',
                      value: programLocalId,
                    },
                    { field: 'page', operator: 'eq', value: 1 },
                  ])
                  return
                }
              }}
            />
          </Form.Item>
        </Form>
      </Card>
      <Table {...tableProps} rowKey="userId">
        <Table.Column
          dataIndex="userId"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title="Nama"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="ppFinalScore"
          title="Nilai Pengajar Praktik"
          render={(value) => <TextField value={trimDecimalString(value)} />}
        />

        <Table.Column
          dataIndex="presensiFinalScore"
          title="Nilai Presensi"
          render={(value) => <TextField value={trimDecimalString(value)} />}
        />

        <Table.Column
          dataIndex="canvasFinalScore"
          title="Nilai Canvas"
          render={(value) => <TextField value={trimDecimalString(value)} />}
        />

        <Table.Column
          dataIndex="totalScore"
          title="Total Nilai"
          render={(value) => {
            return value || 0
          }}
        />

        <Table.Column
          dataIndex="Action"
          title="Action"
          render={(_, record: any) => (
            <Space direction="vertical">
              <Button
                size="small"
                type="default"
                icon={<Icons.EyeOutlined />}
                onClick={() => {
                  setSelectedUserId(record)
                }}
              >
                Lihat Detail
              </Button>
            </Space>
          )}
        />
      </Table>

      <Modal
        title={
          <div>
            Detail Nilai <Tag>{selectedUserId.name}</Tag>
          </div>
        }
        visible={!!selectedUserId.userId}
        onCancel={() => setSelectedUserId('')}
        footer={null}
        width="70%"
        destroyOnClose
      >
        {userScoreDetail?.data ? (
          <Descriptions
            bordered
            size="small"
            column={1}
            className="overflow-y-scroll h-[70vh]"
          >
            <Descriptions.Item label={<p className="font-extrabold">Item</p>}>
              <p className="font-extrabold">Nilai</p>
            </Descriptions.Item>

            {DetailScoreItemDescription(
              userScoreDetail?.data?.data?.pengajarPraktik,
              'Pengajar Praktik',
            )}

            {DetailScoreItemDescription(
              userScoreDetail?.data?.data?.presensi,
              'Presensi',
            )}

            <Descriptions.Item
              label={
                <h3 className="font-extrabold mt-8">
                  Final Score (Grade) Canvas{' '}
                </h3>
              }
            >
              {' '}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <p>
                  <span className="mr-4">Final Score</span>

                  <span className="font-extrabold">
                    ({userScoreDetail.data?.data?.canvas.weightPercentage} %)
                  </span>
                </p>
              }
            >
              {/* {userScoreDetail?.data?.data?.canvas.score} Point */}

              <p>
                <span className="font-extrabold">
                  {trimDecimalString(
                    userScoreDetail.data?.data?.canvas.finalScore,
                  )}{' '}
                  point
                </span>
              </p>
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <h2 className="font-extrabold mt-8 text-blue-70">Total</h2>
              }
            >
              <h2 className="font-extrabold mt-8 text-blue-70">
                {userScoreDetail?.data?.data?.totalScore} Point
              </h2>
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <Empty />
        )}
      </Modal>
    </div>
  )
}

export default TabNilaiAkhir
