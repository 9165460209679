import clsx from 'clsx'
import { Canvas, FabricImage, FabricObject, FabricText } from 'fabric'
import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import {
  FileType2,
  FileImage,
  FileType,
  ImageIcon,
  Menu,
  TypeOutlineIcon,
  FileQuestion,
  FileCode2,
} from 'lucide-react'

import { extractVariableKey } from '../../utils/canvas'
import { QR_CODE_CERTIFICATE_KEY } from '../../constants'

const OrderNavigatorItem = (props: {
  canvas: Canvas | null
  item: FabricObject
  activeObjectCustomId: string | null
  customId: string | number
}) => {
  const { canvas, item, activeObjectCustomId } = props
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.customId })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const renderItemDetail = (item: FabricObject) => {
    if (!!item.customVariableKey) {
      return extractVariableKey(item.customVariableKey)
    }
    if (item instanceof FabricText) {
      return item.text
    }
    if (item instanceof FabricImage) {
      return <em>Gambar</em>
    }
    return <em>Komponen Lain</em>
  }

  const renderItemIcon = (item: FabricObject) => {
    if (item.customVariableKey) {
      if (item instanceof FabricImage) {
        if (item.customVariableKey === QR_CODE_CERTIFICATE_KEY) {
          return <FileCode2 className="h-4 w-4" />
        }
        return <FileImage className="h-4 w-4" />
      }
      if (
        item instanceof FabricText &&
        item.customVariableKey.startsWith("attributes['")
      ) {
        return <FileType className="h-4 w-4" />
      }
      if (item instanceof FabricText) return <FileType2 className="h-4 w-4" />
    }

    if (item instanceof FabricImage) return <ImageIcon className="h-4 w-4" />
    if (item instanceof FabricText)
      return <TypeOutlineIcon className="h-4 w-4" />

    return <FileQuestion className="h-4 w-4" />
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={clsx(
        'rounded shadow border p-2 mb-1 cursor-pointer gap-2 grid grid-cols-[20px_1fr_16px] items-center',
        'select-none',
        item.customId === activeObjectCustomId
          ? 'certificate-template__order-navigator-item--selected shadow-none animate-[0.8s_pulse_infinite]'
          : 'certificate-template__order-navigator-item',
        item.customVariableKey
          ? 'border-orange-40 text-orange-500'
          : 'border-subdued',
        item.customVariableKey === QR_CODE_CERTIFICATE_KEY &&
          'border-red-80 text-red-500',
      )}
      onClick={() => {
        canvas?.setActiveObject(item)
        canvas?.requestRenderAll()
      }}
    >
      <Menu
        className={clsx(
          'cursor-grab active:cursor-grabbing outline-none h-5 w-5',
        )}
        {...attributes}
        {...listeners}
      />
      <div
        className={clsx(
          'whitespace-nowrap overflow-hidden text-ellipsis text-xs',
          item.customVariableKey && ' font-mono',
        )}
      >
        {renderItemDetail(item)}
      </div>
      {renderItemIcon(item)}
    </div>
  )
}

export default OrderNavigatorItem
