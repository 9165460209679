import { Modal, Radio, useModalForm } from '@pankod/refine-antd'
import React, { useState } from 'react'
import { useInvalidate, useResource } from '@pankod/refine-core'

import ModalParseCSV from '@components/ModalParseCSV'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type CertificateParticipantFlagUploadButton = {
  children?: React.ReactNode
  disabled?: boolean
}

const CertificateParticipantFlagUploadButton = ({
  children,
  disabled,
}: CertificateParticipantFlagUploadButton) => {
  const [value, setValue] = useState('non-instruktur-webinar')
  const [popoverShow, setPopoverShow] = useState(false)
  const { id: programId } = useResource({
    resourceNameOrRouteName: 'lms-program-management',
  })
  const invalidate = useInvalidate()

  const handleClose = () => {
    setPopoverShow(false)
    setValue('non-instruktur-webinar')
  }

  const {
    modalProps: modalPropsInstruktur,
    formProps: formPropsInstruktur,
    close: closeInstruktur,
    submit: submitInstruktur,
    show: showInstruktur,
  } = useModalForm({
    action: 'create',
    resource: `programs/${programId}/instructors/certificate-flags`,
    submit: (formValue) => {
      const flagSertifikat = formValue.data
      return {
        flagSertifikat,
      }
    },
    successNotification: {
      message: 'Perubahan flag instruktur webinar berhasil disimpan',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Tedapat gangguan saat menyimpan CSV'),
    onMutationSuccess: () => {
      invalidate({
        resource: `programs/${programId}/participants`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
      handleClose()
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })
  const {
    modalProps: modalPropsPeserta,
    formProps: formPropsPeserta,
    close: closePeserta,
    submit: submitPeserta,
    show: showPeserta,
  } = useModalForm({
    action: 'create',
    resource: `programs/${programId}/participants/certificate-flags`,
    submit: (formValue) => {
      const flagSertifikat = formValue.data
      return {
        flagSertifikat,
      }
    },
    successNotification: {
      message: 'Perubahan flag non-instruktur webinar berhasil disimpan',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Tedapat gangguan saat menyimpan CSV'),
    onMutationSuccess: () => {
      invalidate({
        resource: `programs/${programId}/participants`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
      handleClose()
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const renderContent = () => (
    <div className="grid grid-rows-2 gap-1">
      <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
        <Radio value={'non-instruktur-webinar'}>Non Instruktur Webinar</Radio>
        <Radio value={'instruktur-webinar'}>Instruktur Webinar</Radio>
      </Radio.Group>
    </div>
  )

  return (
    <>
      <span
        onClick={() => {
          if (disabled) return
          setPopoverShow(true)
        }}
      >
        {children}
      </span>
      <Modal
        visible={popoverShow}
        onCancel={handleClose}
        title="Pilih Tipe Partisipan"
        okText="Lanjutkan"
        cancelText="Kembali"
        onOk={() => {
          if (value === 'non-instruktur-webinar') {
            showPeserta()
          } else {
            showInstruktur()
          }
        }}
      >
        {renderContent()}
        <ModalParseCSV
          modalProps={modalPropsPeserta}
          formProps={formPropsPeserta}
          onClose={() => {
            closePeserta()
            handleClose()
          }}
          onSubmit={submitPeserta}
          validCSVHeaders={['userId', 'status']}
          presetValidate={[
            {
              name: 'status',
              entries: ['true', 'false'],
            },
          ]}
          title="Flag Sertifikat Non-Instruktur Webinar"
        />
        <ModalParseCSV
          modalProps={modalPropsInstruktur}
          formProps={formPropsInstruktur}
          onClose={() => {
            closeInstruktur()
            handleClose()
          }}
          onSubmit={submitInstruktur}
          validCSVHeaders={['userId', 'status']}
          presetValidate={[
            {
              name: 'status',
              entries: ['true', 'false'],
            },
          ]}
          title="Flag Sertifikat Instruktur Webinar"
        />
      </Modal>
    </>
  )
}

export default CertificateParticipantFlagUploadButton
