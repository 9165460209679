import React from 'react'
import {
  Button,
  Descriptions,
  Modal,
  Skeleton,
  Tag,
  TagProps,
  TextField,
} from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'

import { TCommonResponse } from 'src/interfaces/common'
import { ParticipantDataSource, ParticipantDetail } from '../type'
import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'

type ParticipantDetailModal = {
  userId: string | null
  programId?: string | string[] | number
  onClose: () => void
}

const sourceLabel: Record<
  ParticipantDataSource,
  { label: string; color: TagProps['color'] }
> = {
  PARTICIPANT_CONSOLIDATION_DATA_EMPTY: { label: '', color: '' },
  PARTICIPANT_CONSOLIDATION_DATA_PRIMARY: {
    label: 'primer',
    color: 'cyan',
  },
  PARTICIPANT_CONSOLIDATION_DATA_SECONDARY: {
    label: 'sekunder',
    color: 'blue',
  },
  PARTICIPANT_CONSOLIDATION_DATA_TERTIARY: {
    label: 'tersier',
    color: 'geekblue',
  },
}

const ParticipantDetailModal = (props: ParticipantDetailModal) => {
  const { data, isFetching } = useOne<TCommonResponse<ParticipantDetail[]>>({
    resource: `programs/${props.programId}/participant-consolidation`,
    dataProviderName: 'lms',
    id: props.userId || -1,
    queryOptions: {
      enabled: Boolean(!!props.programId && !!props.userId),
    },
  })

  const renderData = () => {
    if (isFetching) {
      return (
        <Descriptions
          layout="horizontal"
          bordered
          column={1}
          labelStyle={{ width: '30%' }}
          contentStyle={{ width: '70%' }}
        >
          <Descriptions.Item
            label={
              <Skeleton.Input className="rounded-sm" size="small" active />
            }
          >
            <Skeleton.Input className="rounded-sm" size="small" active block />
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Skeleton.Input className="rounded-sm" size="small" active />
            }
          >
            <Skeleton.Input className="rounded-sm" size="small" active block />
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Skeleton.Input className="rounded-sm" size="small" active />
            }
          >
            <Skeleton.Input className="rounded-sm" size="small" active block />
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Skeleton.Input className="rounded-sm" size="small" active />
            }
          >
            <Skeleton.Input className="rounded-sm" size="small" active block />
          </Descriptions.Item>
        </Descriptions>
      )
    }

    const renderValue = (label: string, value: string) => {
      if (label.toLowerCase() === 'kapabilitas') {
        return kapabilitasMapper(value)
      }
      return value
    }

    return (
      <Descriptions
        layout="horizontal"
        labelStyle={{ width: '30%' }}
        contentStyle={{ width: '70%' }}
        bordered
        column={1}
      >
        {data?.data?.data?.map(({ label, source, value }, index) => (
          <Descriptions.Item label={label} key={index}>
            {source !== 'PARTICIPANT_CONSOLIDATION_DATA_EMPTY' ? (
              <div className="flex gap-2 justify-between">
                <TextField value={renderValue(label, value)} />
                <Tag
                  className="uppercase !ml-2 self-start"
                  color={sourceLabel[source].color}
                >
                  {sourceLabel[source].label}
                </Tag>
              </div>
            ) : (
              <TextField value="Data kosong" italic type="secondary" />
            )}
          </Descriptions.Item>
        ))}
      </Descriptions>
    )
  }

  return (
    <Modal
      visible={Boolean(props.userId)}
      onCancel={props.onClose}
      footer={<Button onClick={props.onClose}>Kembali</Button>}
      title="Detail Partisipan"
      width="700px"
    >
      {renderData()}
    </Modal>
  )
}

export default ParticipantDetailModal
